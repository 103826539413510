<template>
  <div class="app-container">
    <div class="info">
      车辆轨迹
      <span> 车牌号{{ plate_num }} </span>
      <span> 发车时间{{ staer }} </span>
      <span> 到货时间{{ end }} </span>
    </div>
    <div v-show="ishow">
      <div id="container" style="height: 90vh"></div>
    </div>
    <div v-show="!ishow">暂无轨迹</div>
  </div>
</template>

<script>
import { Loading } from "element-ui";
export default {
  name: "index",
  props: {
    plate_num: "",
    start_province: "",
    end_province: "",
    num: "",
    transport_staer: "",
    transport_end: "",
    case_prov: "",
    aim_prov: "",
    truck_tord_num:"",
  },
  data() {
    return {
      // 边界地名
      name: "安徽省",
      // 连线经纬度
      markers: [],
      marl: [],
      staer_time: "",
      end_time: "",
      platenum: "",
      time: [],
      ishow: true,
      staer: "", //发车时间
      end: "", //到货
    };
  },
  watch: {
    num() {
      Loading.service(true);
      this.trajectory();
      this.info_turn();
    },
  },
  mounted() {
    this.trajectory();
    this.info_turn();
  },
  methods: {
    openFullScreen1() {},
    info_turn() {
      this.staer = this.transport_staer;
      this.end = this.transport_end;
      this.platenum = this.plate_num;
    },
    //数据请求
    trajectory() {
      console.log(this.truck_tord_num)
      this.$my.net.req({
        take_over_control: 1,
        api: "https://zwbapi.jungong56.com/",
        data: {
          mod: "res",
          ctr: "get_path_data_app",
          // plate_num: this.plate_num,
          // start_time: this.start_province,
          // end_time: this.end_province,
          truck_tord_num:this.truck_tord_num,
          system_type:2,
        },
        callback: (data) => {
          if (data.code == 0) {
            var arr = [];
            for (var i = 0; i < data.msg.list.length; i++) {
              if (i % 22 == 0) {
                // 每隔22条取出一条数据
                arr.push(data.msg.list[i]);
              }
            }
            var map = [];
            var time = [];
            arr.forEach((item, index) => {
              map.push(item.lon, item.lat);
              time.push(item.date);
            });
            this.time = time;
            var groupedArray = group(map, 2);
            this.markers = groupedArray;
            this.marl = groupedArray;
            this.map_punctuation();
            this.ishow = true;
            // 数组合并
            function group(array, subGroupLength) {
              let index = 0;
              let newArray = [];
              while (index < array.length) {
                newArray.push(array.slice(index, (index += subGroupLength)));
              }
              return newArray;
            }
          } else {
            this.ishow = false;
            // 加载完毕
            let loadingInstance = Loading.service(false);
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close();
            });
            return;
          }
        },
      });
    },
    // 地图标点连线
    map_punctuation() {
      var _this = this;
      var map = new AMap.Map("container", { resizeEnable: true });
      var lnglats = this.markers;
      var infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(0, -30) });
      for (var i = 0, marker; i < lnglats.length; i++) {
        if (i % 5 == 0) {
          var marker = new AMap.Marker({
            position: lnglats[i],
            map: map,
          });
          marker.content = "时间" + _this.time[i];
          marker.on("click", markerClick);
          marker.emit("click", { target: marker });
        }
      }
      function markerClick(e) {
        infoWindow.setContent(e.target.content);
        infoWindow.open(map, e.target.getPosition());
      }

      //连线
      var polyline = new AMap.Polyline({
        path: this.marl,
        isOutline: true,
        outlineColor: "#ffeeff",
        borderWeight: 1,
        strokeColor: "#3366FF",
        strokeOpacity: 1,
        strokeWeight: 2,
        // 折线样式还支持 'dashed'
        strokeStyle: "solid",
        // strokeStyle是dashed时有效
        strokeDasharray: [10, 5],
        lineJoin: "round",
        lineCap: "round",
        zIndex: 50,
      });
      // 创建一个 Icon
      var startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(25, 34),
        // 图标的取图地址
        image:
          "//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png",
        // 图标所用图片大小
        imageSize: new AMap.Size(135, 40),
        // 图标取图偏移量
        imageOffset: new AMap.Pixel(-9, -3),
      });
      // 将 icon 传入 marker
      var startMarker = new AMap.Marker({
        position: this.marl[0],
        icon: startIcon,
        offset: new AMap.Pixel(-13, -30),
      });
      // 创建一个 icon
      var endIcon = new AMap.Icon({
        size: new AMap.Size(25, 34),
        image:
          "//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png",
        imageSize: new AMap.Size(135, 40),
        imageOffset: new AMap.Pixel(-95, -3),
      });
      var arr_type = this.marl.length - 1;
      // 将 icon 传入 marker

      var endMarker = new AMap.Marker({
        position: this.marl[arr_type],
        icon: endIcon,
        offset: new AMap.Pixel(-13, -30),
      });
      infoWindow.open(map, this.marl[arr_type]);
      endMarker.on("click", function (e) {
        e.target.content = "终点";
      });
      //圈边界线 //发车地
      new AMap.DistrictSearch({
        extensions: "all",
        subdistrict: 0,
      }).search(this.case_prov, function (status, result) {
        getData(result.districtList[0]);
      });
      var polygons = [];
      function getData(data, level) {
        var bounds = data.boundaries;
        if (bounds) {
          for (var i = 0, l = bounds.length; i < l; i++) {
            var polygon = new AMap.Polygon({
              map: map,
              strokeWeight: 1,
              strokeColor: "#0091ea",
              fillColor: "#80d8ff",
              fillOpacity: 0.4,
              path: bounds[i],
            });
            polygons.push(polygon);
          }
          map.setFitView(); //地图自适应
        }
      }
      //圈边界线 //到货地
      new AMap.DistrictSearch({
        extensions: "all",
        subdistrict: 0,
      }).search(this.aim_prov, function (status, result) {
        getData(result.districtList[0]);
      });
      var polygons = [];
      function getData(data, level) {
        var bounds = data.boundaries;
        if (bounds) {
          for (var i = 0, l = bounds.length; i < l; i++) {
            var polygon = new AMap.Polygon({
              map: map,
              strokeWeight: 1,
              strokeColor: "#0091ea",
              fillColor: "#80d8ff",
              fillOpacity: 0.4,
              path: bounds[i],
            });
            polygons.push(polygon);
          }
          map.setFitView(); //地图自适应
        }
      }
      map.add(polyline);
      map.add(startMarker);
      map.add(endMarker);
      map.setFitView();
      // 加载完毕
      let loadingInstance = Loading.service(false);
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close();
      });
    },
  },
};
</script>

<style scoped>
.info {
  line-height: 30px;
  font-size: 12px;
}
.button {
  border: 0ch;
}
</style>